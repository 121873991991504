<template>
  <Teleport
    v-if="$props.target && $props.target.modalFooterRef"
    :to="$props.target.modalFooterRef"
  >
    <slot />
  </Teleport>
</template>

<script setup>
defineProps({
  target: {
    type: Object,
    required: true
  }
})
</script>
